import './AdminPage.css';
import { Tabs, Tab, Accordion } from "react-bootstrap"
import WaitingEventsComponent from "./ApprovalsTab/WaitingEventsComponent"
import WaitingUsersComponent from "./ApprovalsTab/WaitingUsersComponent"
import StudentsTable from "./StudentsTab/StudentsTable"
import AddSingleStudentComponent from "./StudentsTab/AddSingleStudentComponent"
import AddStudentsComponent from "./StudentsTab/AddStudentsComponent"
import AdminsTable from "./AdminsTab/AdminsTable"
import AddAdminComponent from "./AdminsTab/AddAdminComponent"
import LessonsComponent from "./LessonsTab/LessonsComponent"

export default function AdminPage() {
  return (
    <div className='admin-page-background'>
      <img className="n-banner" src="https://neotashkelon.tik-tak.net/wp-content/uploadfiles/sites/678/2019/01/%D7%A0%D7%90%D7%95%D7%AA-%D7%90%D7%A9%D7%A7%D7%9C%D7%95%D7%9F-%D7%91%D7%90%D7%A0%D7%A8.jpg" alt="" />
      <Tabs
        justify
        defaultActiveKey="approvalstab"
        id="adminpage-tabs"
        className="mb-3-m">
        <Tab className="mb-3-tab" eventKey="approvalstab" title="בקשות">
          <Accordion>
            <WaitingEventsComponent />
            <WaitingUsersComponent />
          </Accordion>
        </Tab>
        <Tab className="mb-3-tab" eventKey="studentstab" title="תלמידים">
          <div style={{ paddingRight: '20px' }}>
            <AddSingleStudentComponent />{'                          '}
            <AddStudentsComponent />
          </div>
          <StudentsTable />
        </Tab>
        <Tab className="mb-3-tab" eventKey="adminstab" title="מנהלים">
          {/* <AddAdminComponent /> */}
          <AdminsTable />
        </Tab>
        <Tab className="mb-3-tab" eventKey="lessonstab" title="שיעורים">
          <LessonsComponent />
        </Tab>
      </Tabs>
    </div>

  )
}