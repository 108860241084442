import React, { useState, useEffect } from 'react';
import { Table } from "react-bootstrap"

export default function AdminsTable(){
    const [adminsList, setAdminsList] = useState([])
    
    function updateAdminsList(){
      fetch(`/getAdminsList`)
      .then(response => response.json())
      .then(data => setAdminsList(data))
      .catch(error => console.log(error))
    }

    useEffect(() => {
      updateAdminsList()
    }, [adminsList.length]);

    function AdminItem(props){
        const adminObj = props.adminObj
        return(
            <tr>
            <td>{adminObj.firstName}</td>
            <td>{adminObj.lastName}</td>
          </tr>
        )
    }

    const listAdminsInTable = adminsList.map((admin)=>< AdminItem  key = {admin._id} adminObj = {admin}/>)

    return(
        <Table className='a-table' striped bordered hover>
        <thead>
          <tr>
            <th>שם פרטי</th>
            <th>שם משפחה</th>
          </tr>
        </thead>
        <tbody>
            {listAdminsInTable}
        </tbody>
      </Table>
    )
}