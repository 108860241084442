import { useState, useEffect } from 'react';
import { ListGroup } from "react-bootstrap";
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import './teach_a_lesson.css';
import Background from '../Background/Background';


export default function TeachALesson() {
  const [dates, setdates] = useState("");
  const [id, setID] = useState(String);

  useEffect(() => {
    localStorage.setItem('id', JSON.stringify(id));
  }, [id]);

  useEffect(() => {
    fetch('/getdates')
      .then(response => response.json())
      .then(data => setdates(data))
      .catch(error => console.log(error))
  }, []);

  const ListGroupItem = (lgi, index) => {
    if (lgi.dayStatus === true) {
      const formattedDate = lgi.date.split('-')[2] + '/' + lgi.date.split('-')[1] + '/' + lgi.date.split('-')[0]
      return (
        <ListGroup.Item key={index} as="li" >
          <a className="a-green" href="TeachALesson_place" onClick={() => setID(lgi._id)}>יום שישי {formattedDate} </a>
          <p />
        </ListGroup.Item>
      )
    }
  }

  return (
      <Background children={
        <div className="teachALesson">
          <h1> אני רוצה להעביר את זה הלאה בתאריך: </h1>
          <ListGroup className='tl-lg' as="ul">
            {dates === "" ? <LoadingSpinner /> : dates.map(ListGroupItem)}
          </ListGroup>
        </div>
      } />
      );
}





