import React, { useState } from "react";
import "./LoadingSpinner.css";

export default function LoadingSpinner() {
  const [spinner, setSpinner] = useState(
    <div className="spinner-container">
      <div className="loading-spinner" />טוען...
    </div>)

  setTimeout(() => {
    setSpinner(<><h4 style={{direction:"rtl","text-align": "center"}}>אופס!, לא קיימים נתונים 😥</h4></>)
  }, 9000)

  return (
    <>{spinner}</>
  );
}