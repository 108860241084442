import "./LoginPage.css";
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import UsefulFunctions from '../useful_functions';

export default function LoginPage() {
  // React States   
  let navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [loginData, setLoginData] = useState(Object)
  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault()
    var { fname, lname, pass } = document.forms[0];
    const mfname = fname.value.split(' ')[0].replaceAll('`', '').replaceAll('-', ' ')
    const mlname = lname.value.replaceAll(`'`, '').replaceAll('`', '').replaceAll('-', ' ')
    const link = `/login?fname=${mfname}&lname=${mlname}&pass=${pass.value}`
    var requestResult = await (await fetch(link)).json()
 
    if (requestResult === "1") {
      setErrorMessages("שם שגוי")
    }
    else if (requestResult === "2") {
      setErrorMessages("סיסמה שגויה")
    }
    else {
      setLoginData(requestResult)
      setIsSubmitted(true)
      setTimeout(() => {
        document.cookie = `data=${UsefulFunctions.ResultData(requestResult)};`
        document.cookie = `userperm=${UsefulFunctions.ResultPerm(requestResult)};`
        navigate("/User")
      }, 2000)
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = () => (<div className="error">{errorMessages}</div>);

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>שם פרטי </label>
          <input type="text" name="fname" required />
        </div>
        <div className="input-container">
          <label>שם משפחה </label>
          <input type="text" name="lname" required />
        </div>
        <div className="input-container">
          <label>סיסמה </label>
          <input type="password" name="pass" required />
          {renderErrorMessage()}
        </div>
        <div className="button-container">
          <button className="sbmt-btn" type="submit">כניסה</button>
        </div>
      </form>
    </div>
  );

  // JSX code for Welcome screen
  const welcome = (
    <div>התחברות מוצלחת עבור {loginData.firstName} {loginData.lastName}!</div>
  );
  return (UsefulFunctions.CookieToJson("data")!=="" && UsefulFunctions.CookieToJson("userperm")!=="") ? (<Outlet />) : (
        <div className="area">
        <div className="banner-container">
          <img className="n-banner" src="https://neotashkelon.tik-tak.net/wp-content/uploadfiles/sites/678/2019/01/נאות-אשקלון-באנר.jpg" alt="" />
        </div>
        <br></br>
        <div className="app">
          <div className="login-form">
            {isSubmitted ? welcome : renderForm}
          </div>
        </div>
      <ul className="circles"> {/*this entire ul is for the shitty floating cubes that guy stole from the internet*/}
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  )
}
