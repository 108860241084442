import './HomePage.css';
import { HiOutlinePencilAlt } from "react-icons/hi";
import { CgScreen } from "react-icons/cg";
import { VscFeedback } from "react-icons/vsc";
import { SiReadthedocs } from "react-icons/si";
import { GiTeacher } from "react-icons/gi";
import { BsFillCalendar2Fill } from "react-icons/bs";
import Background from "../Background/Background";

export default function HomePage() {
  return (
    <Background children={
      <div className="icon-container">
        <i className="icon"><a href="/TeachALesson_date"><GiTeacher size="80px" /></a><br />העבר את זה הלאה</i>
        <i className="icon"><a href="/ParticipateALesson"> <HiOutlinePencilAlt size="80px" /></a><br />הרשמה לשיעור</i>
        <i className="icon"><a href="/Calendar"><BsFillCalendar2Fill size="80px" /></a><br />לוח שנה</i>
        <i className="icon"><a href="/ReviewPage"><VscFeedback size="80px" /></a><br />משוב</i>
        <i className="icon"><a href="/#" disable="true" style={{color:'grey', cursor:'default'}}><CgScreen size="80px"/></a><br />צפייה</i>
        <i className="icon"><a href="/#" disable="true" style={{color:'grey', cursor:'default'}}><SiReadthedocs size="80px" /></a><br />חומרים</i>
      </div>
      }/>
  );
}


