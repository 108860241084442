import { useState, useEffect } from "react";
import { Accordion, Modal } from "react-bootstrap";
import UsefulFunctions from "../useful_functions";
import ReviewBoxParticipent from "./ReviewBox/ReviewBoxParticipent";
import ReviewBoxTeacher from "./ReviewBox/ReviewBoxTeacher";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Background from "../Background/Background";
import "./ReviewPage.css";

export function SenData(lessonid, userid) {
  try {
    var getQ1Value = document.querySelector('input[name="Q1"]:checked').value;
    var getQ2Value = document.querySelector('input[name="Q2"]:checked').value;
    var getQ3Value = document.querySelector('input[name="Q3"]:checked').value;
    var getQ4Value = document.querySelector('input[name="Q4"]:checked').value;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ eventId: lessonid, userId: userid, Q1: getQ1Value, Q2: getQ2Value, Q3: getQ3Value, Q4: getQ4Value }),
    };

    fetch("/AddComment", requestOptions)
      .then(response => response.json())
      .catch(error => console.log(error))

    setTimeout(() => {
      window.location.reload(false);
    }, 1500)
  }
  catch (err) {
    alert("יש למלא את כל השאלון")
  }
}

export default function ReviewPage() {
  const [datesWithWaitingEvents, setDatesWithWaitingEvents] = useState([]);
  const userData = UsefulFunctions.CookieToJson("data")
  const userPerm = UsefulFunctions.CookieToJson("userperm")

  useEffect(() => {
    if (userPerm === "0") {
      fetch("/getalllessonsforstoudent/" + UsefulFunctions.CookieToJson("data"))
        .then((response) => response.json())
        .then((data) => setDatesWithWaitingEvents(data))
        .catch((error) => console.log(error));
    }
  }, []);

  function EventItem(props) {
    const eventObject = props.eventObject;
    const teacherNameAndClass = `${eventObject.teacher.firstName} ${eventObject.teacher.lastName} מכיתה ${eventObject.teacher.class}`;
    if (eventObject.teacher._id === userData) {
      return (
        <li className="rev-box">
          <div >
            <strong>לימדת {eventObject.title} ב{eventObject.place} בשעה {eventObject.time}</strong>
          </div>
          <br />
          <div>
            <ReviewBoxTeacher userid={userData} lessonid={eventObject._id} />
          </div>
          <hr></hr>
        </li>
      );
    }
    else {
      return (
        <li className="rev-box">
          <div >
            <strong>{teacherNameAndClass} לימד/ה {eventObject.title} ב{eventObject.place}{" "}</strong>
            בשעה {eventObject.time}
          </div>
          <br />
          <div>
            <ReviewBoxParticipent userid={userData} lessonid={eventObject._id} />
          </div>
          <hr></hr>
        </li>
      );
    }
  }

  const listWaitingEvents = function (events) {
    return events.length > 0 ? events.map((event) => (
      <EventItem key={event._id || 0} eventObject={event} />
    )) : <li className="rev-box">
      <div >
        <strong>הגשת את כל המשובים! 😁</strong>

      </div>
    </li>
  };

  function DateItem(props) {
    const dateObject = props.dateObject;
    const splitDateArray = dateObject.date.split("-");
    const formattedDate = `${splitDateArray[2]}/${splitDateArray[1]}`;
    return (
      <div>
        <Accordion className="a-rev-1" defaultActiveKey="0">
          <Accordion.Item eventKey="1">
            <Accordion.Header>שיעורים ביום שישי {formattedDate}</Accordion.Header>
            <Accordion.Body className="a-b-rev-1">
              <ul>{listWaitingEvents(dateObject.events)}</ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
  const listDatesWithWaitingEvents = datesWithWaitingEvents.map((date) => (
    <DateItem key={date._id} dateObject={date} />
  ));

  return (
    <Background children={
      <div className="le-f-rev" style={{ direction: "rtl" }}>
        <h1>שיעורים שהשתתפת בהם </h1>
        <Accordion bsPrefix="a-rev">
          <Accordion.Item className="a-i-rev">
            <Accordion.Body className="a-b-rev">
              {datesWithWaitingEvents.length > 0 ? <ul>{listDatesWithWaitingEvents}</ul> : <LoadingSpinner />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    } />
  );

}



