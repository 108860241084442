import React, { useEffect, useState } from 'react'
import LoadingSpinner from './LoadingSpinner/LoadingSpinner'
import AdminPage from './AdminPage/AdminPage';
import UserPage from './UserPage/UserPage';
import UsefulFunctions from './useful_functions'
import Background from './Background/Background';

export default function UserPermissionRouter() {
    const [userPerm, setUserPerm] = useState(null)

    useEffect(() => {
        setUserPerm(UsefulFunctions.CookieToJson("userperm"))
    }, [])

    return userPerm === "0" ? <UserPage /> :
        userPerm === "1" ? <AdminPage /> :
            <Background children={
                <LoadingSpinner />
            } />
}