import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'

export default function AddSingleStudentComponent() {
    const [showModal, setShowModal] = useState(false)
    const [userData, setUserData] = useState({})

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        setUserData({})
        setShowModal(false);
    }

    const handleSubmit = (event) => {
        event.stopPropagation() //stops form onSubmit from firing when opening/closing modal
        event.preventDefault() //stops page from refreshing
        setUserData({
            "firstName": event.target.firstName.value,
            "lastName": event.target.lastName.value,
            "class": event.target.class.value,
            "classNum": event.target.classNum.value
        })
    }

    useEffect(() => { //useEffect hook to make sure userData is actually set
        if (Object.keys(userData).length > 0) { //if userData is not empty
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userData),
            }
            fetch("/addStudent", requestOptions)
                .then(response => response.json())
                .then(data => console.log(data))
            setTimeout(() => {
                window.location.reload(false);
            }, 1500)
        }
    }, [userData])

    const renderForm = (
        <Form onSubmit={handleSubmit} style={{ "direction": "rtl" }}>
            <Form.Group controlId="firstName">
                <Form.Label>שם פרטי</Form.Label>
                <Form.Control type="text" required />
            </Form.Group>
            <Form.Group controlId="lastName">
                <Form.Label>שם משפחה</Form.Label>
                <Form.Control type="text" required />
            </Form.Group>
            <Form.Group controlId="class">
                <Form.Label>כיתה</Form.Label>
                <Form.Select required>
                    <option value="א">א</option>
                    <option value="ב">ב</option>
                    <option value="ג">ג</option>
                    <option value="ד">ד</option>
                    <option value="ה">ה</option>
                    <option value="ו">ו</option>
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="classNum">
                <Form.Select required>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </Form.Select>
            </Form.Group>
            <div style={{ direction: "ltr" }}>
                <br />
                <Button variant="primary" type="submit" >
                    הוסף
                </Button>
            </div>
        </Form>
    )

    return (
        <>
            <Button onClick={() => handleShowModal()}>הוספת תלמיד יחיד</Button>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    אפשר להשתמש באופציה זאתי אם לא מוצאים תלמיד במאגר
                    {renderForm}
                </Modal.Body>
            </Modal>
        </>
    )
}