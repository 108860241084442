import React, { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { BsUpload } from "react-icons/bs";
import "./StudentsTable.css"
export default function AddStudentsComponent() {
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [errorMessage, setErrorMessage] = useState(<></>)
    const [showModal, setShowModal] = useState(false)
    const [modalSubmitButton, setModalSubmitButton] = useState(<></>)
    const [uploadDisable, setUploadDisable] = useState(false)

    const XLSX_FILE_MIMETYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    useEffect(() => {
    }, [uploadedFiles])

    const handleShowModal = () => {
        setShowModal(true)
        setModalSubmitButton(<></>)
    }

    const handleCloseModal = () => {
        setShowModal(false)
        setUploadedFiles(null)
        setErrorMessage(<></>)
        setModalSubmitButton(<></>)
        setUploadDisable(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault() //stops page from refreshing
        event.stopPropagation() //stops form onSubmit from firing when opening/closing modal

        const formData = new FormData()
        for (const file of uploadedFiles) {
            formData.append("xlsxFiles", file)
        }

        const requestOptions = {
            method: "POST",
            body: formData
        }

        fetch("/UploadStudentsXLSXFile/AddStudents", requestOptions)
            .then(response => response.json())
            .then(data => setErrorMessage(data))

        setTimeout(() => {
            window.location.reload(false);
        }, 1500)
    }

    const handleFileChange = (event) => {
        try {
            if (event.target.files.length > 0) {
                setUploadDisable(true)
                setErrorMessage(
                    <h5>מעלה קבצים...</h5>
                )

                var filesArray = []

                for (var file of event.target.files) {
                    if (file.type !== XLSX_FILE_MIMETYPE) {
                        setModalSubmitButton(<></>)
                        setErrorMessage(
                            <h5>קובץ שנבחר אינו תואם</h5>
                        )
                        throw new Error("קובץ שנבחר אינו תואם")
                    }
                    else {
                        filesArray.push(file)
                    }
                }

                setUploadedFiles(filesArray)
                setTimeout(() => {
                    setUploadDisable(false)
                    setErrorMessage(<></>)
                    setModalSubmitButton(
                        <div style={{ direction: "ltr" }}>
                            <br />
                            <Button variant="primary" type="submit" onClick={setModalSubmitButton(<></>)}>
                                הוסף
                            </Button>
                        </div>
                    )
                }, 2000)
            }
        }
        catch (err) {
            setUploadDisable(false)
            console.log(err.message)
        }

    }

    const renderForm = (
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group controlId="xlsxFiles">
                <Form.Control
                    type="file"
                    multiple
                    name="xlsxFiles"
                    accept={XLSX_FILE_MIMETYPE}
                    onChange={handleFileChange}
                    disabled={uploadDisable}
                    style={{ direction: "ltr" }} />
            </Form.Group>
            <br />
            {modalSubmitButton}
        </Form >
    )

    return (
        <>
            <Button onClick={() => handleShowModal()}>הוספת תלמידים מאקסל</Button>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton />
                <Modal.Body style={{ direction: "rtl" }}>
                    <strong>יש לבחור קבצי excel התואמים לפורמט</strong>
                    <br />
                    {renderForm} {errorMessage}
                </Modal.Body>
            </Modal>
        </>
    )
}