import React from 'react'
import './NotFoundPage.css';
export default function NotFoundPage() {
    return (
        <div className="area">
            <div className="banner-container">
                <img className="n-banner" src="https://neotashkelon.tik-tak.net/wp-content/uploadfiles/sites/678/2019/01/נאות-אשקלון-באנר.jpg" alt="" />
            </div>
            <br></br>

            <div className='m-404'>
                העמוד לא נמצא
                <br></br>
                <a href='/'>לחץ כאן לחזרה לעמוד הראשי</a>
            </div>
            <ul className="circles"> {/*this entire ul is for the shitty floating cubes that guy stole from the internet*/}
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
            </ul>
        </div>
    )
}