import { Accordion, Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import "../AdminPage.css"

export default function WaitingEventsComponent() {
    const [datesWithWaitingEvents, setDatesWithWaitingEvents] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [eventResponse, setEventResponse] = useState("")
    const [fileInfo, setFileInfo] = useState({})

    const updateDatesWithWaitingEvents = () => {
        fetch(`/getDatesWithWaitingEvents`)
            .then(response => response.json())
            .then(data => setDatesWithWaitingEvents(data))
            .catch(error => console.log(error))
    }

    const handleShowModal = () => setShowModal(true)
    const handleCloseModal = () => {
        updateDatesWithWaitingEvents()
        setEventResponse("")
        setShowModal(false);
    }

    const handleNewEventStatus = (eventId, newStatus) => {
        const url = `/updateEventStatus/${newStatus ? "1" : "2"}`
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ "eventId": eventId })
        }
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => setEventResponse(data))
            .catch(error => console.log(error))
        updateDatesWithWaitingEvents()
        handleShowModal()
    }

    const handleFileDownload = (fileId) => {
        fetch("/getFileInfo/" + fileId)
            .then(response => response.json())
            .then(data => setFileInfo(data))
    }

    useEffect(() => {
        if (Object.keys(fileInfo).length > 0) {
            fetch("/downloadFile/" + fileInfo.fileId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        fileInfo.originalFileName
                    );
                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);

                    setFileInfo({})
                });
        }
    }, [fileInfo]);

    useEffect(() => {
        updateDatesWithWaitingEvents()
    }, [datesWithWaitingEvents.length]);

    function EventItem(props) {
        const eventObject = props.eventObject
        const teacherNameAndClass = `${eventObject.teacher.firstName} ${eventObject.teacher.lastName} משכבה ${eventObject.teacher.class}`
        if (eventObject.file) {
            return (
                <>
                    <li>
                        {teacherNameAndClass} מבקש/ת להעביר הלאה {eventObject.title} ב{eventObject.place} בשעה {eventObject.time}
                        <br />
                        קובץ מצגת: {eventObject.file.originalFileName}
                        <br />
                        <Button onClick={() => handleFileDownload(eventObject.file.fileId)}>להורדת קובץ המצגת</Button>
                        <br />
                        <p></p>
                        <Button variant="outline-success" size="sm"
                            onClick={() => handleNewEventStatus(eventObject._id, true)}>  אישור   </Button>{'               '}
                        <Button variant="outline-danger" size="sm"
                            onClick={() => handleNewEventStatus(eventObject._id, false)}>  ביטול  </Button>

                    </li>
                    <br />
                </>

            )
        }
        else {
            return (
                <>
                    <li className="we-a-li">
                        <div>
                            {teacherNameAndClass} מבקש/ת להעביר הלאה {eventObject.title} ב{eventObject.place} בשעה {eventObject.time}
                        </div>
                        ללא קובץ מצגת<br />
                        <Button variant="outline-success" size="sm"
                            onClick={() => handleNewEventStatus(eventObject._id, true)}>אישור</Button>{'               '}
                        <Button variant="outline-danger" size="sm"
                            onClick={() => handleNewEventStatus(eventObject._id, false)}>ביטול</Button>
                    </li>
                    <br />
                </>


            )
        }
    }

    const listWaitingEvents = function (events) { return events.map((event) => <EventItem key={event._id || 0} eventObject={event} />) }

    function DateItem(props) {
        const dateObject = props.dateObject
        const splitDateArray = dateObject.date.split('-')
        const formattedDate = `${splitDateArray[2]}/${splitDateArray[1]}`
        return (
            <div>
                <h5> בקשות ליום שישי {formattedDate}</h5>
                <ul>{listWaitingEvents(dateObject.events)}</ul>
            </div>

        )
    }
    const listDatesWithWaitingEvents = datesWithWaitingEvents.map((date) => <DateItem key={date._id} dateObject={date} />)

    return (
        <>
            <Accordion.Item eventKey="0" >
                <Accordion.Header bsPrefix="we-accordion-h">בקשות להעביר הלאה</Accordion.Header>
                <Accordion.Body bsPrefix="we-a-ul">
                    {datesWithWaitingEvents.length > 0 ? <ul className="we-a-content">{listDatesWithWaitingEvents}</ul> : <div className="we-a-content">לא קיימות בקשות</div >}
                </Accordion.Body>
            </Accordion.Item>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title><div className="wl-m-res">{eventResponse}</div></Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div style={{direction:"ltr"}}>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            סגור
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

