import "./ParticipateALesson.css"
import { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Background from "../Background/Background";
import UsefulFunctions from "../useful_functions";

export default function ParticipateALesson() {
  const [datesWithEvents, setDatesWithWaitingEvents] = useState([]);
  const [lessonid, setlessonID] = useState(String);
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    localStorage.setItem('id', JSON.stringify(lessonid));
  }, [lessonid]);

  useEffect(() => {
    const URL = "/getUserData/" + UsefulFunctions.CookieToJson("data")
    fetch(URL)
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => console.log(error))
  }, [])


  useEffect(() => {
    fetch(`/getalllessons`)
      .then((response) => response.json())
      .then((data) => setDatesWithWaitingEvents(data))
      .catch((error) => console.log(error));
  }, []);

  function EventItem(props) {
    let myclass = userData.class;
    const eventObject = props.eventObject;
    const teacherNameAndClass = `${eventObject.teacher.firstName} ${eventObject.teacher.lastName} מכיתה ${eventObject.teacher.class}`;

    function nextClass() {
      try {
        var nc = String.fromCharCode(myclass.charCodeAt(myclass.length - 1) + 1);
        return nc
      } catch (err) {
        console.log(err);
      }
    }
    if ((eventObject.teacher.class === myclass || eventObject.teacher.class === nextClass())
      && userData._id !== eventObject.teacher._id
      && eventObject.studentCount > 0
      && !eventObject.ApprovedParticipants.includes(userData._id)
      && !eventObject.WaitedParticipants.includes(userData._id)) {
      return userData.perm === 0 ? (
        <li>
          <div><a className="part-a-href" href="/ParticipateALesson_thenks" onClick={() => setlessonID(eventObject._id)}>
            {teacherNameAndClass} מלמד/ת {eventObject.title} ב{eventObject.place}{" "}
            בשעה {eventObject.time}
          </a>
          </div>
        </li>
      ) : userData.perm === 1 ? (
        <li>
          <div><a className="part-a-href" href="/" onClick={() => setlessonID(eventObject._id)}>
            {teacherNameAndClass} מלמד/ת {eventObject.title} ב{eventObject.place}{" "}
            בשעה {eventObject.time}
          </a>
          </div>
        </li>
      ) : null
    }
  }

  const listWaitingEvents = function (events) {
    return events.map((event) => (
      <EventItem key={event._id || 0} eventObject={event} />
    ));
  };

  function DateItem(props) {
    const dateObject = props.dateObject;
    const splitDateArray = dateObject.date.split("-");
    const formattedDate = `${splitDateArray[2]}/${splitDateArray[1]}`;
    return (
      <div>
        <Accordion className="a-part-1" defaultActiveKey="0">
          <Accordion.Item eventKey="1">
            <Accordion.Header> יום שישי {formattedDate}</Accordion.Header>
            <Accordion.Body className="a-b-part-1">
              <ul>{listWaitingEvents(dateObject.events)}</ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }

  const listDatesWithEvents = datesWithEvents.map((date) => (
    <DateItem key={date._id} dateObject={date} />
  ));


  return (
    <Background children={
      <div className="le-f-participation">
        <h1>שיעורים פתוחים להרשמה </h1>
        <Accordion bsPrefix="a-part">
          <Accordion.Item className="a-i-part">
            <Accordion.Body className="a-b-part">
              {datesWithEvents.length > 0 ? <ul>{listDatesWithEvents}</ul> : <LoadingSpinner />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    } />
  );

}


