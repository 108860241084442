var CryptoJS = require("crypto-js");

export default class UsefulFunctions {
    static async AuthCheck(cookie) {
        if (!cookie) return false
        var data = this.CookieToJson("data")
        var link = `/validate?j=` + JSON.stringify(data)
        return await (await fetch(link)).json()
    }

    static ResultData(res) {
        return res._id
    }

    static ResultPerm(res) {
        return res.perm
    }

    /**
     * function gets a cookie name as a string and returns it's value
     * @param {String} cname 
    */
    static CookieToJson(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static DeleteCookie(cname) {
        document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }

    static Encrypt(string) {
        try {
            var encryptedText = CryptoJS.AES.encrypt(string, process.env.REACT_APP_ENC_SECRET).toString()
            return encryptedText
        }
        catch (error) {
            return error.message
        }

    }

    static Decrypt(string) {
        try {
            var bytes = CryptoJS.AES.decrypt(string, process.env.REACT_APP_ENC_SECRET);
            var decryptedText = bytes.toString(CryptoJS.enc.Utf8);
            return decryptedText
        }
        catch (error) {
            return error.message
        }

    }
}