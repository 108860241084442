import "./FileAndTopic.css"
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import UsefulFunctions from "../../useful_functions"
import { useNavigate } from 'react-router-dom'
import Background from "../../Background/Background"

export default function TopicAndFile() {
  const [topic, setTopic] = useState("")
  const [uploadedFile, setUploadedFile] = useState()
  const [errorMessage, setErrorMessage] = useState(<></>)
  const [showModal, setShowModal] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [uploadDisabled, setUploadDisabled] = useState(false)
  const [uploadLabel, setUploadLabel] = useState("העלאת קובץ")

  const userPerm = UsefulFunctions.CookieToJson("userperm")

  const items = {
    id: JSON.parse(localStorage.getItem("id")),
    place: JSON.parse(localStorage.getItem("place")),
    teacher: UsefulFunctions.CookieToJson("data"),
    time: JSON.parse(localStorage.getItem("time"))
  }

  let navigate = useNavigate()

  const validTopic = new RegExp("[^\u0590-\u05FF 0-9-,]")

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setRedirect(true)
  }

  const validate = () => {
    if (validTopic.test(topic)) {
      alert(" אנא הכנס אותיות בעברית ומספרים בלבד")
      setTopic("")
    }
  }

  useEffect(() => {
    localStorage.setItem('title', JSON.stringify(topic))
  }, [topic]);

  useEffect(() => {
    if (redirect) {
      localStorage.clear()
      navigate("/User")
    }
  }, [redirect, navigate])

  const handleFileChange = (event) => {
    try {
      setErrorMessage(<></>)
      setUploadDisabled(true)
      setUploadedFile(event.target.files[0])
      setErrorMessage(
        <h5>מעלה קובץ..</h5>
      )
      setTimeout(() => {
        setErrorMessage(<>{event.target.files[0].name} עלה בהצלחה</>)
        setUploadDisabled(false)
        setUploadLabel("שינוי קובץ")
      }, 1000)
    }
    catch (err) {
      console.log(err.message)
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault() //stops page from refreshing
    event.stopPropagation() //stops form onSubmit from firing when opening/closing modal
    if (topic.length > 0) {
      items['title'] = JSON.parse(localStorage.getItem("title"))
      if (userPerm !== "1") {
        if (uploadedFile) {
          const formData = new FormData()
          formData.append("pfile", uploadedFile)
          formData.append("rbody", JSON.stringify(items))

          const FileRequestOptions = {
            method: "POST",
            body: formData
          }

          fetch("/AddEvent/UploadPresentationFile", FileRequestOptions)
            .then(response => response.json())
            .then(data => console.log(data))
        }
        else {
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(items)
          }

          fetch("/AddEvent", requestOptions)
            .then(response => response.json())
            .then(data => console.log(data))
        }
      }
      handleShowModal()
    }
    else {
      alert("יש למלא נושא שיעור")
    }
  }

  return (
    <Background children={
      <div className="file-and-topic">

        <div className="topic-div">
          <h1>נושא השיעור</h1>
          <input type="text"
            placeholder="מה נושא השיעור"
            value={topic}
            onChange={(e) => { setTopic(e.target.value); validate() }}>
            {validate()}
          </input>
        </div>

        <p />

        <div className="file-div">
          <h1>מצגת</h1>
          <label htmlFor="pfile" className="p-upload-button">{uploadLabel}</label>
          <input type="file"
            id="pfile"
            name="pfile"
            hidden

            onChange={handleFileChange}
            disabled={uploadDisabled} />
          <p />
          {errorMessage}
        </div>

        <div className="d-submit-btn">
          <br />
          <button className="submit-btn" type="submit" onClick={handleSubmit} hidden={uploadDisabled}> שליחה </button>
        </div>

        <>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton />
            <Modal.Body style={{ direction: "rtl" }}>
              <h2>הרשמתך התקבלה בהצלחה</h2>
              <h2>מחכים ומצפים</h2>
              <h2>שתעביר/י את זה הלאה</h2>
              <h2>ברגע שהשיעור יאושר, יחכה לך אישור באיזור האישי</h2>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={handleCloseModal}>👍</button>
            </Modal.Footer>
          </Modal>
        </>
      </div>
    } />
  )
}







