import "./UserPage.css"
import React, { useState, useEffect } from 'react';
import UsefulFunctions from "../useful_functions";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Background from "../Background/Background";
export default function UserPage() {
    const [userInfo, setUserInfo] = useState({})
    const [eventsInfo, setEventsInfo] = useState({})

    const updateUserInfo = () => {
        const URL = "/getUserData/" + UsefulFunctions.CookieToJson("data")
        fetch(URL)
            .then(response => response.json())
            .then(data => setUserInfo(data))
            .catch(error => console.log(error))
    }

    const updateEventsInfo = () => {
        const URL = "/getPendingEvents/" + UsefulFunctions.CookieToJson("data")
        fetch(URL)
            .then(response => response.json())
            .then(data => setEventsInfo(data))
            .catch(error => console.log(error))
    }

    useEffect(() => {
        updateUserInfo()
        updateEventsInfo()
    }, [eventsInfo.length]);

    function EventItem(props) {

        const eventObject = props.eventObject
        const title = eventObject.title
        const place = eventObject.place
        return (
            <li>{title} ב{place}</li>
        )
    }

    return (
        <Background children={(eventsInfo && Object.keys(eventsInfo).length > 0) ? (
            <div className="user-info-container">
                <h1>שלום, {userInfo.firstName} {userInfo.lastName} 🙂</h1>
                <div className="p-j-container">
                    <div className="p-j-block">
                        <br />
                        <strong>⌛בקשות להעביר הלאה שעוד לא אושרו: </strong>
                        <h3>{eventsInfo.pendingEvents.length > 0
                            ? eventsInfo.pendingEvents.map((event) => <EventItem key={event._id} eventObject={event} />)
                            : <div>אין</div>}</h3>
                    </div>
                    <div className="p-j-block">
                        <br />
                        <h5><strong>✅בקשות להעביר הלאה שאושרו: </strong></h5>
                        <h3>{eventsInfo.acceptedEvents.length > 0
                            ? eventsInfo.acceptedEvents.map((event) => <EventItem key={event._id} eventObject={event} />)
                            : <div>אין</div>}</h3>
                    </div>
                    <div className="p-j-block">
                        <br />
                        <h5><strong>⏳בקשות להצטרפות לשיעור שעוד לא אושרו: </strong></h5>
                        <h3>{eventsInfo.pendingLessons.length > 0
                            ? eventsInfo.pendingLessons.map((event) => <EventItem key={event._id} eventObject={event} />)
                            : <div>אין</div>}</h3>
                    </div>
                    <div className="p-j-block">
                        <br />
                        <h5><strong>✔️בקשות להצטרפות לשיעור שאושרו: </strong></h5>
                        <h3>{eventsInfo.approvedLessons.length > 0
                            ? eventsInfo.approvedLessons.map((event) => <EventItem key={event._id} eventObject={event} />)
                            : <div>אין</div>}</h3>
                    </div>
                </div>
            </div>
        ) : <LoadingSpinner />} />)
}