import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import "./places.css";
import Background from "../../Background/Background";

const cardStyle = {
   width: '10rem',
  'background-color':'rgba(255, 255, 255, 0.49)',
  'padding':'0px'
}

function PicAPlace() {
  const [place, setplace] = useState(String);
  const [time, setime] = useState("");
  const [exsistplace, setexsistplace] = useState("");
  const places = [
    ["ספריה", "https://cdn-icons-png.flaticon.com/512/4524/4524607.png"],
    ["מרחב פדגוגי", "https://cdn-icons-png.flaticon.com/512/4359/4359835.png"],
    ["חדר משחקים", "https://cdn-icons-png.flaticon.com/512/6811/6811259.png"],
    ["מרחב חיצוני", "https://cdn-icons-png.flaticon.com/512/7297/7297514.png"],
    //["מגרש / אולם","https://cdn-icons-png.flaticon.com/512/4329/4329327.png"]
  ];

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("id"));
    fetch("/GeTime/" + id)
      .then((response) => response.json())
      .then((data) => setexsistplace(data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    localStorage.setItem("place", JSON.stringify(place));
  }, [place]);
  
  useEffect(() => {
    localStorage.setItem("time", JSON.stringify(time));
  }, [time]);

  function TimeTaken(TT, rpt, index) {
    if (TT === 0) {
      return (
        <a className="a-green"
          href="TeachALesson_fileAndTopic"
          onClick={() => {
            setime(index + 1);
            setplace(rpt);
          }}
        >
          <h1>שעה {index + 1}</h1>
        </a>
      );
    } else {
      return <h1 className="a-red">שעה {index + 1}</h1>;
    }
  }

  const RPT = (rpt) => {
    try {
      return exsistplace[rpt].map(function (x, index) {
        return TimeTaken(x, rpt, index);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const PlaceTaken = (PT, index) => {
    if (Object.keys(exsistplace).includes(PT[0])) {
      return (
          <Card border="info" style={cardStyle} key={index}>
            <Card.Img variant="top" src={PT[1]} />
            <Card.Body style={{padding: '0px'}}>
              <Card.Title><h2>{PT[0]}</h2></Card.Title>
              {RPT(PT[0])}
            </Card.Body>
          </Card>
        
      );
    }
  };

  return (
      <Background children={
        exsistplace === "" ? <LoadingSpinner /> : <div className="cards">{places.map(PlaceTaken)}<br></br></div>
        }/>
  );
}

export default PicAPlace;
