import React, { useState, useEffect } from "react";
import UsefulFunctions from "../useful_functions";

export default function ThenksAfterParticipate() {
  const [items, setItems] = useState({});

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem("id"));
    let user = UsefulFunctions.CookieToJson("data");
    if (items) {
      setItems({ id,user });
    }
  }, []);
  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(items),
    };
    fetch("/AddParticipates", requestOptions).then((response) =>
      response.json()
    );
    localStorage.clear();
  });
  
  return (
    <div>
      <div hidden={true}>{setTimeout("location.href = '/'", 7000)}</div>
      <div className="thenks">
        <h1>הרשמתך התקבלה בהצלחה</h1>
        <h4>ברגע שהרשמתך תאושר, יחכה לך אישור באיזור האישי</h4>
      </div>
    </div>
  );
}
