import { Accordion, Button, Modal } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import "../AdminPage.css"

export default function WaitingUsersComponent() {
    const [datesWithEventsWithWaitingUsers, setDatesWithEventsWithWaitingUsers] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [eventResponse, setEventResponse] = useState("")

    const updateDatesWithEventsWithWaitingUsers = () => {
        fetch(`/getDatesWithEventsWithWaitingUsers`)
            .then(response => response.json())
            .then(data => setDatesWithEventsWithWaitingUsers(data))
            .catch(error => console.log(error))
    }

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => {
        updateDatesWithEventsWithWaitingUsers()
        setEventResponse("")
        setShowModal(false);
    }
    
    const handleUserRequest = (userId, eventId, newStatus) => {
        const reqBody = { "userId": userId, "eventId": eventId, "newStatus": newStatus }
        const url = `/changeUserRequestStatus`
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody)
        }
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => setEventResponse(data))
            .catch(error => console.log(error))
        updateDatesWithEventsWithWaitingUsers()
        handleShowModal()
    }

    useEffect(() => {
        updateDatesWithEventsWithWaitingUsers()
    }, [datesWithEventsWithWaitingUsers.length]);

    function UserItem(props) {
        const userObject = props.userObject
        const eventId = props.eventId
        const userNameAndClass = `${userObject.firstName} ${userObject.lastName} משכבה ${userObject.class}`
        return (
            <>
                <li className="we-a-li">
                    {userNameAndClass} מבקש/ת להצטרף
                    <div style={{ "right": "80px" }}>
                        <Button variant="outline-success" size="sm"
                            onClick={() => handleUserRequest(userObject._id, eventId, true)}>  אישור   </Button>{'               '}
                        <Button variant="outline-danger" size="sm"
                            onClick={() => handleUserRequest(userObject._id, eventId, false)}>  ביטול  </Button>
                    </div>
                </li>
                <br />
            </>
        )
    }

    const listWaitingUsers = function (waitingList, eventId) { return waitingList.map((user) => <UserItem key={user._id} userObject={user} eventId={eventId} />) }

    function EventItem(props) {
        const eventObject = props.eventObject
        return (
            <>
                <li><h6>{eventObject.title} ב{eventObject.place} בשעה {eventObject.time}<br />{eventObject.studentCount} מקומות פנוים</h6></li>
                {listWaitingUsers(eventObject.WaitedParticipants, eventObject._id)}
            </>
        )
    }

    const listEvents = function (events) { return events.map((event) => <EventItem key={event._id} eventObject={event} />) }

    function DateItem(props) {
        const dateObject = props.dateObject
        const splitDateArray = dateObject.date.split('-')
        const formattedDate = `${splitDateArray[2]}/${splitDateArray[1]}`
        return (
            <>
                <h5> <strong>שיעורים ביום שישי {formattedDate}</strong></h5>
                {listEvents(dateObject.events)}
            </>
        )
    }

    const listDatesWithEventsWithWaitingUsers = datesWithEventsWithWaitingUsers.map((date) => <DateItem key={date._id} dateObject={date} />)

    return (
        <>
            <Accordion.Item eventKey="1">
                <Accordion.Header bsPrefix="we-accordion-h">בקשות הצטרפות</Accordion.Header>
                <Accordion.Body bsPrefix="we-a-ul">
                    {datesWithEventsWithWaitingUsers.length > 0 ? <ul className="we-a-content">{listDatesWithEventsWithWaitingUsers}</ul> : <div className="we-a-content">לא קיימות בקשות</div >}
                </Accordion.Body>
            </Accordion.Item>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header>
                    <Modal.Title><div className="wl-m-res">{eventResponse}</div></Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <div style={{direction:"ltr"}}>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            סגור
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}