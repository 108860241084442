import { ListGroup } from "react-bootstrap";
import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

function checkAnyEventsApproved(eventsArray) {
    for (const event of eventsArray) {
        if (event.eventStatus === 1) return true
    }
}

export default function LessonsComponent() {
    const [dates, setDates] = useState([]);

    useEffect(() => {
        fetch(`/getAllDates`)
            .then(response => response.json())
            .then(data => setDates(data))
            .catch(error => console.log(error))

    }, []);

    //returns list item with event title and a place if event status is approved(eventStatus == 1), otherwise returns null
    function EventItem(props) {

        const eventObject = props.eventObject
        return eventObject.eventStatus === 1 ?
            eventObject.studentCount > 0 ?
                <li>{eventObject.title}  ב{eventObject.place} | {eventObject.studentCount} מקומות פנויים </li> : //if event status is approved and student count is greater than 0
                <li>{eventObject.title}  ב{eventObject.place} | אין מקומות פנויים </li> : //if event status is approved and student count is not greater than 0
            null // if event status is not approved
    }

    const listEvents = function (events) { return events.map((event) => <EventItem key={event._id || 0} eventObject={event} />) }

    //returns accordion item with date as header and unordered list of events as body
    function DateItem(props) {
        const dateObject = props.dateObject
        if (dateObject.dayStatus) {
            for (let i = 0; i < dateObject.events.length; i++) {
                if (dateObject.events[i].eventStatus !== 1) {
                    dateObject.events.splice(i, 1)
                }
            }
            const splitDateArray = dateObject.date.split('-')
            const formattedDate = `${splitDateArray[2]}/${splitDateArray[1]}`
            if (dateObject.events.length === 0 || !checkAnyEventsApproved(dateObject.events)) { //if there are no events that day or no approved events
                return (
                    <>
                        <h4 className="t-dayname"> יום שישי {formattedDate}</h4>
                        <ListGroup.Item className='day-body'>לא מתקיימים שיעורים</ListGroup.Item>
                    </>
                )
            }
            else { //if there are events that day
                return (
                    <>
                        <h4 className="t-dayname">יום שישי {formattedDate}</h4>
                        <ListGroup.Item className='day-body'>{listEvents(dateObject.events)}</ListGroup.Item>
                    </>
                )
            }
        }
    }

    const listDates = dates.map((date) => <DateItem key={date._id} dateObject={date} />)

    return dates.length > 0 ? <ListGroup className='day-list-group'>{listDates}</ListGroup> : <LoadingSpinner />

}




