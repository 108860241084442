import "./StudentsTable.css"
import React, { useState, useEffect } from 'react';
import { BiSearchAlt } from 'react-icons/bi'
import { ImCross } from 'react-icons/im'
import { AiOutlineFileExcel } from 'react-icons/ai'
import { Table } from 'react-bootstrap'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'

import * as XLSX from "xlsx";

export default function StudentsTable() {
  const [studentsList, setStudentsList] = useState([])
  const [studentsListWithPassword, setStudentsListWithPassword] = useState([])
  const [search, setSearch] = useState(false)
  const [queryfname, setQueryfname] = useState('')
  const [querylname, setQuerylname] = useState('')
  const [queryclass, setQueryclass] = useState('')
  const [exportStudentsBtn, setExportStudentsBtn] = useState(<></>)

  function updateStudentsList() {
    fetch(`/getStudentsList`)
      .then(response => response.json())
      .then(data => setStudentsList(data))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if (!search || studentsList.length === 0) {
      updateStudentsList()
    }
  }, [search, studentsList.length]);

  useEffect(() => {
    fetch(`/getStudentsListWithPassword`)
      .then(response => response.json())
      .then(data => setStudentsListWithPassword(data))
      .catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if (studentsListWithPassword.length > 0) {
      setExportStudentsBtn(<button className="xprt-btn" onClick={handleExportClick}>ייצוא ססמאות <i><AiOutlineFileExcel size="20px" /></i></button>)
    }
  }, [studentsListWithPassword.length])

  const handleExportClick = () => {
    try {
      let classa = []
      let classb = []
      let classc = []
      let classd = []
      let classe = []
      let classf = []

      for (const studentObj of studentsListWithPassword) {
        delete studentObj._id
        const studentObjHE = { "כיתה": studentObj.class + `'` + studentObj.classNum, "שם פרטי": studentObj.firstName, "שם משפחה": studentObj.lastName, "סיסמה": studentObj.password }
        switch (studentObj.class) {
          case 'א':
            classa.push(studentObjHE)
            break;
          case 'ב':
            classb.push(studentObjHE)
            break;
          case 'ג':
            classc.push(studentObjHE)
            break;
          case 'ד':
            classd.push(studentObjHE)
            break;
          case 'ה':
            classe.push(studentObjHE)
            break;
          case 'ו':
            classf.push(studentObjHE)
            break;
          default:
            console.log("unable to push")
            console.log(studentObj)
        }
      }
      const worksheeta = XLSX.utils.json_to_sheet(classa);
      const worksheetb = XLSX.utils.json_to_sheet(classb);
      const worksheetc = XLSX.utils.json_to_sheet(classc);
      const worksheetd = XLSX.utils.json_to_sheet(classd);
      const worksheete = XLSX.utils.json_to_sheet(classe);
      const worksheetf = XLSX.utils.json_to_sheet(classf);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheeta, "שכבה א");
      XLSX.utils.book_append_sheet(workbook, worksheetb, "שכבה ב");
      XLSX.utils.book_append_sheet(workbook, worksheetc, "שכבה ג");
      XLSX.utils.book_append_sheet(workbook, worksheetd, "שכבה ד");
      XLSX.utils.book_append_sheet(workbook, worksheete, "שכבה ה");
      XLSX.utils.book_append_sheet(workbook, worksheetf, "שכבה ו");

      XLSX.writeFile(workbook, "ססמאות תלמידים.xlsx", { compression: true });
    }
    catch (err) {
      console.log(err.message)
    }
  }

  const submitSearch = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        "firstName": queryfname.split(' ')[0].replaceAll('`', '').replaceAll('-', ' '),
        "lastName": querylname.replaceAll(`'`, '').replaceAll('`', '').replaceAll('-', ' '),
        "className": queryclass
      }),
    }
    fetch("/searchStudents", requestOptions)
      .then((response) => response.json())
      .then(data => setStudentsList(data))
      .catch(error => console.log(error))
  }

  const handleSearchButton = () => {
    setSearch(true)
    submitSearch()
  }

  const handleCancelSearch = () => {
    setSearch(false)
    setQueryfname('')
    setQuerylname('')
    setQueryclass('')
  }

  const renderSearchForm = (
    <div style={{ paddingRight: '20px' }}>
      חיפוש:
      <div className='search-form'>
        <input value={queryfname} onChange={(e) => { setQueryfname(e.target.value) }} placeholder="שם פרטי" />
        <input value={querylname} onChange={(e) => { setQuerylname(e.target.value) }} placeholder="שם משפחה" />
        <select onChange={(e) => { setQueryclass(e.target.value) }}>
          <option value="">- - -</option>
          <option value="א">א</option>
          <option value="ב">ב</option>
          <option value="ג">ג</option>
          <option value="ד">ד</option>
          <option value="ה">ה</option>
          <option value="ו">ו</option>
        </select>
        <div className='srch-crss-btn-container'>
          <button className='srch-btn' onClick={handleSearchButton}><BiSearchAlt size="16px" /></button>
          {search ? <button className='crss-btn' onClick={handleCancelSearch}><ImCross size="16px" /></button> : <></>}
        </div>
      </div>
    </div>
  )

  function StudentItem(props) {
    const studentObj = props.studentObj
    return (
      <tr>
        <td>{studentObj.firstName}</td>
        <td>{studentObj.lastName}</td>
        <td>{studentObj.class}</td>
      </tr>
    )
  }

  const listStudentsInTable = studentsList.map((student) => < StudentItem key={student._id} studentObj={student} />)

  return studentsList.length > 0 ? (
    <>
      <p />
      <div>
        {renderSearchForm}
      </div>
      <hr />
      <div style={{ paddingRight: '20px', paddingLeft: '10px' }}>
        {exportStudentsBtn}
        <Table className='a-table' striped bordered hover size="sm" id="headerTable">
          <thead>
            <tr>
              <th>שם פרטי</th>
              <th>שם משפחה</th>
              <th>שכבה</th>
            </tr>
          </thead>
          <tbody>
            {listStudentsInTable}
          </tbody>
        </Table>
      </div>

    </>

  ) : <LoadingSpinner />
}