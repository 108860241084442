import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import HomePage from './HomePage/HomePage';
import TeachALesson from './teach_a_lesson/teach_a_lesson';
import PicAPlace from './teach_a_lesson/places/places';
import TopicAndFile from './teach_a_lesson/file_and_Topic/FileAndTopic';
import LoginPage from './LoginPage/LoginPage';
import ThenksAfterParticipate from './Participate_a_lesson/thenksAfterParticipate'
import ParticipateALesson from './Participate_a_lesson/ParticipateALesson';
import Calendar from './Calendar/Calendar';
import UserPermissionRouter from './UserPermissionRouter';
import ReviewPage from './reviewpage/ReviewPage';
import FourOhFourPage from './NotFoundPage/NotFoundPage';

import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="*" element={<LoginPage />} >
        <Route path="*" element={<FourOhFourPage />} />
        <Route exact path="" element={<HomePage />} />
        <Route exact path="ParticipateALesson" element={<ParticipateALesson />} />
        <Route exact path="ParticipateALesson_thenks" element={<ThenksAfterParticipate />} />
        <Route exact path="TeachALesson_date" element={<TeachALesson />} />
        <Route exact path="TeachALesson_place" element={<PicAPlace />} />
        <Route exact path="TeachALesson_fileAndTopic" element={<TopicAndFile />} />
        <Route exact path="Calendar" element={<Calendar />}/>
        <Route exact path="ReviewPage" element={<ReviewPage/>} />
        <Route exact path="User" element={<UserPermissionRouter/>} >
        
        </Route>
      </Route>
    </Routes>
  </BrowserRouter >
);
