import "./ReviewBox.css";
import { SenData } from "../ReviewPage";
import {useState} from 'react'

export default function ReviewBoxParticipent(props) {
  return (
    <div className="review-box">
      <div className="comment-box ">
        <h4>הוסף משוב</h4>

        <div className="row">
          <div className="Q1">
            <br />
            <h5>אני שבע רצון מהשיעור בו השתתפתי</h5>
            <input type="radio" name="Q1" value="3" id="3" />
            <label htmlFor="3">במידה רבה</label>

            <br />
            <input type="radio" name="Q1" value="2" id="2" />
            <label htmlFor="2">במידה בינונית</label>

            <br />
            <input type="radio" name="Q1" value="1" id="1" />
            <label htmlFor="1">במידה מועטה</label>
          </div>
        </div>


        <div className="row">
          <div className="Q2">
            <br />
            <h5>האם השיעור תרם לך?</h5>
            <input type="radio" name="Q2" value="3" id="3" />
            <label htmlFor="3">כן - במידה רבה</label>

            <br />
            <input type="radio" name="Q2" value="2" id="2" />
            <label htmlFor="2">כן - במידה בינונית</label>

            <br />
            <input type="radio" name="Q2" value="1" id="1" />
            <label htmlFor="1">כלל לא</label>
          </div>
        </div>

        <div className="row">
          <div className="Q3">
            <br />
            <h5>ארצה להשתתף בשיעורים נוספים</h5>
            <input type="radio" name="Q3" value="3" id="3" required/>
            <label htmlFor="3">במידה רבה</label>

            <br />
            <input type="radio" name="Q3" value="2" id="2" required/>
            <label htmlFor="2">במידה בינונית</label>

            <br />
            <input type="radio" name="Q3" value="1" id="1" required/>
            <label htmlFor="1">כלל לא</label>
          </div>
        </div>

        <div className="row">
          <div className="Q4">
            <br />
            <h5>ארצה להעביר שיעור ולהשתתף בחונכויות</h5>
            <input type="radio" name="Q4" value="3" id="3" />
            <label htmlFor="3">במידה רבה</label>

            <br />
            <input type="radio" name="Q4" value="2" id="2" />
            <label htmlFor="2">במידה בינונית</label>

            <br />
            <input type="radio" name="Q4" value="1" id="1" />
            <label htmlFor="1">במידה קטנה</label>
          </div>
        </div>


        <div className="comment-btns mt-2">
          <div className="row">
            {/* <div className="col-6">
              <div className="pull-left">
                <button className="btn btn-danger btn-sm">בטל</button>
              </div>
            </div> */}

            <br />

            <div className="col-6">
              <div className="pull-right">
                <button
                  className="btn btn-success send btn-sm"
                  onClick={() => SenData(props.lessonid, props.userid)}
                >
                  שלח
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
