import { RiAdminFill } from "react-icons/ri";
import { AiFillHome } from "react-icons/ai";
import { RiLogoutBoxRLine } from "react-icons/ri";
import './Background.css';
import UsefulFunctions from "../useful_functions";
export default function Background({ children }) {

  const handleLogout = () => {
    localStorage.clear()
    UsefulFunctions.DeleteCookie("userperm")
    UsefulFunctions.DeleteCookie("data")
    window.location.reload(false);
  }
  
  return (
    <div className="area">
      <div className="banner-container">
        <img className="n-banner" src="https://neotashkelon.tik-tak.net/wp-content/uploadfiles/sites/678/2019/01/נאות-אשקלון-באנר.jpg" alt=""/>
      </div>
      <br></br>
      {children}
      <br></br>
      <ul className="circles"> {/*this entire ul is for the shitty floating cubes that guy stole from the internet*/}
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
      <br></br>
      <div className="footer">
        <i className="footer-icon"><a href="/User"><RiAdminFill /></a></i>
        <i className="footer-icon"><a href="/"><AiFillHome /></a></i>
        <i className="footer-icon"><a onClick={handleLogout}><RiLogoutBoxRLine /></a></i>
      </div>
    </div>
  );
}


